import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "gatsby";

import { Button, Layout } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/** ============================================================================
 * @component
 * Thank you page.
 */
const ThankyouPage = ({ location }) => (
  <Layout>
    <Container>
      <h2 className="h1">Message received.</h2>
      <p
        className="b1"
        css={css`
          width: 50%;
          text-align: center;
        `}
      >
        Thanks for connecting. Your message has made it's way to DIRTT. We'll
        get back to you before the end of the next business day.
      </p>

      <Link to="/">
        <Button title="Back to home">
          <span>Back to home</span>
        </Button>
      </Link>
    </Container>
  </Layout>
);

export default ThankyouPage;
